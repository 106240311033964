/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MemberImportDiscrepancyDto
 */
export interface MemberImportDiscrepancyDto {
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'memberImportExternalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'points'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'tierLevelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImportDiscrepancyDto
     */
    'discrepancyType'?: MemberImportDiscrepancyDtoDiscrepancyTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberImportDiscrepancyDtoDiscrepancyTypeEnum {
    DUPLICATE = 'DUPLICATE',
    INVALID_DATA = 'INVALID_DATA'
}


