/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { TierLevel } from './tier-level';

/**
 * 
 * @export
 * @interface TierConfigurationSnapshotAllOf
 */
export interface TierConfigurationSnapshotAllOf {
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'businessEntityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'thresholdUnit'?: TierConfigurationSnapshotAllOfThresholdUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'resetDate'?: TierConfigurationSnapshotAllOfResetDateEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'resetPeriod'?: TierConfigurationSnapshotAllOfResetPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'gracePeriod'?: TierConfigurationSnapshotAllOfGracePeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'resetMonth'?: TierConfigurationSnapshotAllOfResetMonthEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'status'?: TierConfigurationSnapshotAllOfStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'activatedWhen'?: string;
    /**
     * 
     * @type {Array<TierLevel>}
     * @memberof TierConfigurationSnapshotAllOf
     */
    'tiers'?: Array<TierLevel>;
}

/**
    * @export
    * @enum {string}
    */
export enum TierConfigurationSnapshotAllOfThresholdUnitEnum {
    POINTS = 'POINTS',
    DOLLARS_SPENT = 'DOLLARS_SPENT',
    NUMBER_OF_TRANSACTIONS = 'NUMBER_OF_TRANSACTIONS'
}
/**
    * @export
    * @enum {string}
    */
export enum TierConfigurationSnapshotAllOfResetDateEnum {
    TIER_ACTIVATION = 'TIER_ACTIVATION',
    LEVEL_UPDATE = 'LEVEL_UPDATE',
    SPECIFIC_MONTH = 'SPECIFIC_MONTH'
}
/**
    * @export
    * @enum {string}
    */
export enum TierConfigurationSnapshotAllOfResetPeriodEnum {
    ONE_YEAR = 'ONE_YEAR',
    ONE_AND_HALF_YEARS = 'ONE_AND_HALF_YEARS',
    TWO_YEARS = 'TWO_YEARS',
    TWO_AND_HALF_YEARS = 'TWO_AND_HALF_YEARS',
    THREE_YEARS = 'THREE_YEARS'
}
/**
    * @export
    * @enum {string}
    */
export enum TierConfigurationSnapshotAllOfGracePeriodEnum {
    HALF_YEAR = 'HALF_YEAR',
    ONE_YEAR = 'ONE_YEAR',
    ONE_AND_HALF_YEAR = 'ONE_AND_HALF_YEAR',
    TWO_YEARS = 'TWO_YEARS',
    TWO_AND_HALF_YEARS = 'TWO_AND_HALF_YEARS',
    THREE_YEARS = 'THREE_YEARS'
}
/**
    * @export
    * @enum {string}
    */
export enum TierConfigurationSnapshotAllOfResetMonthEnum {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum TierConfigurationSnapshotAllOfStatusEnum {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE'
}


