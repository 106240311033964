/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ReferralProgramSnapshotAllOf
 */
export interface ReferralProgramSnapshotAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'businessEntityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'status'?: ReferralProgramSnapshotAllOfStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'referralTarget'?: ReferralProgramSnapshotAllOfReferralTargetEnum;
    /**
     * 
     * @type {number}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'referrerRewardAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'refereeRewardAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'referralGoal'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'referralLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReferralProgramSnapshotAllOf
     */
    'repetition'?: ReferralProgramSnapshotAllOfRepetitionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferralProgramSnapshotAllOfStatusEnum {
    PAUSED = 'PAUSED',
    PUBLISHED = 'PUBLISHED'
}
/**
    * @export
    * @enum {string}
    */
export enum ReferralProgramSnapshotAllOfReferralTargetEnum {
    REFERRER = 'REFERRER',
    REFERRER_AND_REFEREE = 'REFERRER_AND_REFEREE'
}
/**
    * @export
    * @enum {string}
    */
export enum ReferralProgramSnapshotAllOfRepetitionEnum {
    ALL_TIME = 'ALL_TIME',
    YEARLY = 'YEARLY',
    MONTHLY = 'MONTHLY'
}


