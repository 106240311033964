/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MemberSnapshotAllOf
 */
export interface MemberSnapshotAllOf {
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'tierLevelId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSnapshotAllOf
     */
    'manualTierLevel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'status'?: MemberSnapshotAllOfStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'businessEntityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'consumerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'gender'?: MemberSnapshotAllOfGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSnapshotAllOf
     */
    'ghostAccount'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'joiningType'?: MemberSnapshotAllOfJoiningTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MemberSnapshotAllOf
     */
    'importExternalId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberSnapshotAllOfStatusEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DEPRECATED = 'DEPRECATED',
    FORMER = 'FORMER'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberSnapshotAllOfGenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberSnapshotAllOfJoiningTypeEnum {
    IN_APP = 'IN_APP',
    BUSINESS_LANDING = 'BUSINESS_LANDING',
    IN_STORE = 'IN_STORE'
}


