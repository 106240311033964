/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OfferSnapshotAllOfSpecialRecurringDate
 */
export interface OfferSnapshotAllOfSpecialRecurringDate {
    /**
     * 
     * @type {string}
     * @memberof OfferSnapshotAllOfSpecialRecurringDate
     */
    'month'?: OfferSnapshotAllOfSpecialRecurringDateMonthEnum;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOfSpecialRecurringDate
     */
    'monthValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferSnapshotAllOfSpecialRecurringDate
     */
    'dayOfMonth'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferSnapshotAllOfSpecialRecurringDateMonthEnum {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER'
}


