/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetail } from '../models';
/**
 * CampaignControllerApi - axios parameter creator
 * @export
 */
export const CampaignControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'SMS' | 'EMAIL'} campaignChannel 
         * @param {Array<'MEMBER' | 'GUEST' | 'BLOCKED'>} statuses 
         * @param {Array<'MALE' | 'FEMALE' | 'OTHER'>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembersCount1: async (campaignChannel: 'SMS' | 'EMAIL', statuses: Array<'MEMBER' | 'GUEST' | 'BLOCKED'>, genders?: Array<'MALE' | 'FEMALE' | 'OTHER'>, tierLevels?: Array<string>, ageFrom?: number, ageTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignChannel' is not null or undefined
            assertParamExists('getCampaignMembersCount1', 'campaignChannel', campaignChannel)
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('getCampaignMembersCount1', 'statuses', statuses)
            const localVarPath = `/campaigns/members/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignChannel !== undefined) {
                localVarQueryParameter['campaignChannel'] = campaignChannel;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }

            if (genders) {
                localVarQueryParameter['genders'] = Array.from(genders);
            }

            if (tierLevels) {
                localVarQueryParameter['tierLevels'] = Array.from(tierLevels);
            }

            if (ageFrom !== undefined) {
                localVarQueryParameter['ageFrom'] = ageFrom;
            }

            if (ageTo !== undefined) {
                localVarQueryParameter['ageTo'] = ageTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignControllerApi - functional programming interface
 * @export
 */
export const CampaignControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'SMS' | 'EMAIL'} campaignChannel 
         * @param {Array<'MEMBER' | 'GUEST' | 'BLOCKED'>} statuses 
         * @param {Array<'MALE' | 'FEMALE' | 'OTHER'>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignMembersCount1(campaignChannel: 'SMS' | 'EMAIL', statuses: Array<'MEMBER' | 'GUEST' | 'BLOCKED'>, genders?: Array<'MALE' | 'FEMALE' | 'OTHER'>, tierLevels?: Array<string>, ageFrom?: number, ageTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignMembersCount1(campaignChannel, statuses, genders, tierLevels, ageFrom, ageTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignControllerApi - factory interface
 * @export
 */
export const CampaignControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CampaignControllerApiGetCampaignMembersCount1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembersCount1(requestParameters: CampaignControllerApiGetCampaignMembersCount1Request, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getCampaignMembersCount1(requestParameters.campaignChannel, requestParameters.statuses, requestParameters.genders, requestParameters.tierLevels, requestParameters.ageFrom, requestParameters.ageTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCampaignMembersCount1 operation in CampaignControllerApi.
 * @export
 * @interface CampaignControllerApiGetCampaignMembersCount1Request
 */
export interface CampaignControllerApiGetCampaignMembersCount1Request {
    /**
     * 
     * @type {'SMS' | 'EMAIL'}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly campaignChannel: 'SMS' | 'EMAIL'

    /**
     * 
     * @type {Array<'MEMBER' | 'GUEST' | 'BLOCKED'>}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly statuses: Array<'MEMBER' | 'GUEST' | 'BLOCKED'>

    /**
     * 
     * @type {Array<'MALE' | 'FEMALE' | 'OTHER'>}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly genders?: Array<'MALE' | 'FEMALE' | 'OTHER'>

    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly tierLevels?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly ageFrom?: number

    /**
     * 
     * @type {number}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly ageTo?: number
}

/**
 * CampaignControllerApi - object-oriented interface
 * @export
 * @class CampaignControllerApi
 * @extends {BaseAPI}
 */
export class CampaignControllerApi extends BaseAPI {
    /**
     * 
     * @param {CampaignControllerApiGetCampaignMembersCount1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public getCampaignMembersCount1(requestParameters: CampaignControllerApiGetCampaignMembersCount1Request, options?: AxiosRequestConfig) {
        return CampaignControllerApiFp(this.configuration).getCampaignMembersCount1(requestParameters.campaignChannel, requestParameters.statuses, requestParameters.genders, requestParameters.tierLevels, requestParameters.ageFrom, requestParameters.ageTo, options).then((request) => request(this.axios, this.basePath));
    }
}
