/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TierBreakdownMetricDtoGroup
 */
export interface TierBreakdownMetricDtoGroup {
    /**
     * 
     * @type {string}
     * @memberof TierBreakdownMetricDtoGroup
     */
    'tierLevelType'?: TierBreakdownMetricDtoGroupTierLevelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TierBreakdownMetricDtoGroup
     */
    'tierLevelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TierBreakdownMetricDtoGroup
     */
    'tierLevelName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TierBreakdownMetricDtoGroup
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof TierBreakdownMetricDtoGroup
     */
    'percentage'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TierBreakdownMetricDtoGroupTierLevelTypeEnum {
    ASSIGNED = 'ASSIGNED',
    NOT_ASSIGNED = 'NOT_ASSIGNED',
    UNKNOWN = 'UNKNOWN'
}


