/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BalanceDto } from '../models';
// @ts-ignore
import { ClaimedRewardDto } from '../models';
// @ts-ignore
import { GiftPointsDto } from '../models';
// @ts-ignore
import { InvitationDto } from '../models';
// @ts-ignore
import { MemberCountersDto } from '../models';
// @ts-ignore
import { MemberDto } from '../models';
// @ts-ignore
import { MemberEcModeDto } from '../models';
// @ts-ignore
import { MemberTotalFilteredDto } from '../models';
// @ts-ignore
import { PagedClaimedRewardDto } from '../models';
// @ts-ignore
import { PagedClaimedRewardReportDto } from '../models';
// @ts-ignore
import { PagedMemberDto } from '../models';
// @ts-ignore
import { ProblemDetail } from '../models';
// @ts-ignore
import { PurchaseDto } from '../models';
// @ts-ignore
import { TierProgressDto } from '../models';
// @ts-ignore
import { UpdateTierLevelIdDto } from '../models';
/**
 * MemberControllerApi - axios parameter creator
 * @export
 */
export const MemberControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {GiftPointsDto} giftPointsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGiftPoints: async (id: string, giftPointsDto: GiftPointsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addGiftPoints', 'id', id)
            // verify required parameter 'giftPointsDto' is not null or undefined
            assertParamExists('addGiftPoints', 'giftPointsDto', giftPointsDto)
            const localVarPath = `/members/{id}/gift-points`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftPointsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        block: async (id: string, comment: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('block', 'id', id)
            // verify required parameter 'comment' is not null or undefined
            assertParamExists('block', 'comment', comment)
            const localVarPath = `/members/{id}/status/blocked`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} rewardId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClaimedRewardsForReport: async (rewardId: string, from?: string, to?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            assertParamExists('getClaimedRewardsForReport', 'rewardId', rewardId)
            const localVarPath = `/members/claimed-rewards/{rewardId}`
                .replace(`{${"rewardId"}}`, encodeURIComponent(String(rewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {boolean} includeCollaborationDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberBalances: async (memberId: string, includeCollaborationDetails: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getMemberBalances', 'memberId', memberId)
            // verify required parameter 'includeCollaborationDetails' is not null or undefined
            assertParamExists('getMemberBalances', 'includeCollaborationDetails', includeCollaborationDetails)
            const localVarPath = `/members/{memberId}/balances`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCollaborationDetails !== undefined) {
                localVarQueryParameter['includeCollaborationDetails'] = includeCollaborationDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} claimedRewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberClaimedReward: async (memberId: string, claimedRewardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getMemberClaimedReward', 'memberId', memberId)
            // verify required parameter 'claimedRewardId' is not null or undefined
            assertParamExists('getMemberClaimedReward', 'claimedRewardId', claimedRewardId)
            const localVarPath = `/members/{memberId}/claimed-rewards/{claimedRewardId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"claimedRewardId"}}`, encodeURIComponent(String(claimedRewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {Array<'CLAIMED' | 'REDEEMED' | 'EXPIRED'>} [statuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberClaimedRewards: async (memberId: string, statuses?: Array<'CLAIMED' | 'REDEEMED' | 'EXPIRED'>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getMemberClaimedRewards', 'memberId', memberId)
            const localVarPath = `/members/{memberId}/claimed-rewards`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMemberDetails', 'id', id)
            const localVarPath = `/members/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'MEMBER' | 'GUEST' | 'BLOCKED'} [status] 
         * @param {string} [tierLevel] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers: async (status?: 'MEMBER' | 'GUEST' | 'BLOCKED', tierLevel?: string, search?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (tierLevel !== undefined) {
                localVarQueryParameter['tierLevel'] = tierLevel;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersCounters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/members/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTierProgress: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTierProgress', 'id', id)
            const localVarPath = `/members/{id}/tier-progress`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'MEMBER' | 'GUEST' | 'BLOCKED'} [status] 
         * @param {string} [tierLevel] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalFiltered: async (status?: 'MEMBER' | 'GUEST' | 'BLOCKED', tierLevel?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/members/total-filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (tierLevel !== undefined) {
                localVarQueryParameter['tierLevel'] = tierLevel;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvitationDto} invitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite: async (invitationDto: InvitationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationDto' is not null or undefined
            assertParamExists('invite', 'invitationDto', invitationDto)
            const localVarPath = `/members/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGhostConsumer: async (memberId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('inviteGhostConsumer', 'memberId', memberId)
            const localVarPath = `/members/{memberId}/invitation-ghost`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvitationDto} invitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyInvitation: async (invitationDto: InvitationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationDto' is not null or undefined
            assertParamExists('notifyInvitation', 'invitationDto', invitationDto)
            const localVarPath = `/members/invitation-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} businessEntityId 
         * @param {string} claimedRewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemClaimedReward: async (memberId: string, businessEntityId: string, claimedRewardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('redeemClaimedReward', 'memberId', memberId)
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('redeemClaimedReward', 'businessEntityId', businessEntityId)
            // verify required parameter 'claimedRewardId' is not null or undefined
            assertParamExists('redeemClaimedReward', 'claimedRewardId', claimedRewardId)
            const localVarPath = `/members/{memberId}/business-entities/{businessEntityId}/claimed-rewards/{claimedRewardId}/redeem`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)))
                .replace(`{${"claimedRewardId"}}`, encodeURIComponent(String(claimedRewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} claimedRewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemMemberClaimedReward: async (memberId: string, claimedRewardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('redeemMemberClaimedReward', 'memberId', memberId)
            // verify required parameter 'claimedRewardId' is not null or undefined
            assertParamExists('redeemMemberClaimedReward', 'claimedRewardId', claimedRewardId)
            const localVarPath = `/members/{memberId}/claimed-rewards/{claimedRewardId}/redeem`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"claimedRewardId"}}`, encodeURIComponent(String(claimedRewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPurchase: async (id: string, purchaseDto: PurchaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('registerPurchase', 'id', id)
            // verify required parameter 'purchaseDto' is not null or undefined
            assertParamExists('registerPurchase', 'purchaseDto', purchaseDto)
            const localVarPath = `/members/{id}/purchase`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMemberEcMode: async (search: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('searchMemberEcMode', 'search', search)
            const localVarPath = `/members/ec-mode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTierLevelIdDto} updateTierLevelIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTierLevel: async (id: string, updateTierLevelIdDto: UpdateTierLevelIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setTierLevel', 'id', id)
            // verify required parameter 'updateTierLevelIdDto' is not null or undefined
            assertParamExists('setTierLevel', 'updateTierLevelIdDto', updateTierLevelIdDto)
            const localVarPath = `/members/{id}/tierLevelId`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTierLevelIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblock: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unblock', 'id', id)
            const localVarPath = `/members/{id}/status/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberControllerApi - functional programming interface
 * @export
 */
export const MemberControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {GiftPointsDto} giftPointsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGiftPoints(id: string, giftPointsDto: GiftPointsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGiftPoints(id, giftPointsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async block(id: string, comment: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.block(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} rewardId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClaimedRewardsForReport(rewardId: string, from?: string, to?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedClaimedRewardReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClaimedRewardsForReport(rewardId, from, to, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} memberId 
         * @param {boolean} includeCollaborationDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberBalances(memberId: string, includeCollaborationDetails: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BalanceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberBalances(memberId, includeCollaborationDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} claimedRewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberClaimedReward(memberId: string, claimedRewardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimedRewardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberClaimedReward(memberId, claimedRewardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} memberId 
         * @param {Array<'CLAIMED' | 'REDEEMED' | 'EXPIRED'>} [statuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberClaimedRewards(memberId: string, statuses?: Array<'CLAIMED' | 'REDEEMED' | 'EXPIRED'>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedClaimedRewardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberClaimedRewards(memberId, statuses, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'MEMBER' | 'GUEST' | 'BLOCKED'} [status] 
         * @param {string} [tierLevel] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembers(status?: 'MEMBER' | 'GUEST' | 'BLOCKED', tierLevel?: string, search?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMemberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembers(status, tierLevel, search, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersCounters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembersCounters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTierProgress(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierProgressDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTierProgress(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'MEMBER' | 'GUEST' | 'BLOCKED'} [status] 
         * @param {string} [tierLevel] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalFiltered(status?: 'MEMBER' | 'GUEST' | 'BLOCKED', tierLevel?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberTotalFilteredDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalFiltered(status, tierLevel, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InvitationDto} invitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invite(invitationDto: InvitationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invite(invitationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteGhostConsumer(memberId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteGhostConsumer(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InvitationDto} invitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyInvitation(invitationDto: InvitationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyInvitation(invitationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} businessEntityId 
         * @param {string} claimedRewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemClaimedReward(memberId: string, businessEntityId: string, claimedRewardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimedRewardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemClaimedReward(memberId, businessEntityId, claimedRewardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} claimedRewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemMemberClaimedReward(memberId: string, claimedRewardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimedRewardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemMemberClaimedReward(memberId, claimedRewardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPurchase(id: string, purchaseDto: PurchaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPurchase(id, purchaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMemberEcMode(search: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberEcModeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMemberEcMode(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTierLevelIdDto} updateTierLevelIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTierLevel(id: string, updateTierLevelIdDto: UpdateTierLevelIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTierLevel(id, updateTierLevelIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unblock(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unblock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberControllerApi - factory interface
 * @export
 */
export const MemberControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MemberControllerApiAddGiftPointsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGiftPoints(requestParameters: MemberControllerApiAddGiftPointsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addGiftPoints(requestParameters.id, requestParameters.giftPointsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiBlockRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        block(requestParameters: MemberControllerApiBlockRequest, options?: AxiosRequestConfig): AxiosPromise<MemberDto> {
            return localVarFp.block(requestParameters.id, requestParameters.comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetClaimedRewardsForReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClaimedRewardsForReport(requestParameters: MemberControllerApiGetClaimedRewardsForReportRequest, options?: AxiosRequestConfig): AxiosPromise<PagedClaimedRewardReportDto> {
            return localVarFp.getClaimedRewardsForReport(requestParameters.rewardId, requestParameters.from, requestParameters.to, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetMemberBalancesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberBalances(requestParameters: MemberControllerApiGetMemberBalancesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BalanceDto>> {
            return localVarFp.getMemberBalances(requestParameters.memberId, requestParameters.includeCollaborationDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetMemberClaimedRewardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberClaimedReward(requestParameters: MemberControllerApiGetMemberClaimedRewardRequest, options?: AxiosRequestConfig): AxiosPromise<ClaimedRewardDto> {
            return localVarFp.getMemberClaimedReward(requestParameters.memberId, requestParameters.claimedRewardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetMemberClaimedRewardsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberClaimedRewards(requestParameters: MemberControllerApiGetMemberClaimedRewardsRequest, options?: AxiosRequestConfig): AxiosPromise<PagedClaimedRewardDto> {
            return localVarFp.getMemberClaimedRewards(requestParameters.memberId, requestParameters.statuses, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetMemberDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDetails(requestParameters: MemberControllerApiGetMemberDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<MemberDto> {
            return localVarFp.getMemberDetails(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetMembersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers(requestParameters: MemberControllerApiGetMembersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedMemberDto> {
            return localVarFp.getMembers(requestParameters.status, requestParameters.tierLevel, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersCounters(options?: AxiosRequestConfig): AxiosPromise<MemberCountersDto> {
            return localVarFp.getMembersCounters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetTierProgressRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTierProgress(requestParameters: MemberControllerApiGetTierProgressRequest, options?: AxiosRequestConfig): AxiosPromise<TierProgressDto> {
            return localVarFp.getTierProgress(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiGetTotalFilteredRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalFiltered(requestParameters: MemberControllerApiGetTotalFilteredRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MemberTotalFilteredDto> {
            return localVarFp.getTotalFiltered(requestParameters.status, requestParameters.tierLevel, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiInviteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite(requestParameters: MemberControllerApiInviteRequest, options?: AxiosRequestConfig): AxiosPromise<MemberDto> {
            return localVarFp.invite(requestParameters.invitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiInviteGhostConsumerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGhostConsumer(requestParameters: MemberControllerApiInviteGhostConsumerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.inviteGhostConsumer(requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiNotifyInvitationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyInvitation(requestParameters: MemberControllerApiNotifyInvitationRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.notifyInvitation(requestParameters.invitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiRedeemClaimedRewardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemClaimedReward(requestParameters: MemberControllerApiRedeemClaimedRewardRequest, options?: AxiosRequestConfig): AxiosPromise<ClaimedRewardDto> {
            return localVarFp.redeemClaimedReward(requestParameters.memberId, requestParameters.businessEntityId, requestParameters.claimedRewardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiRedeemMemberClaimedRewardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemMemberClaimedReward(requestParameters: MemberControllerApiRedeemMemberClaimedRewardRequest, options?: AxiosRequestConfig): AxiosPromise<ClaimedRewardDto> {
            return localVarFp.redeemMemberClaimedReward(requestParameters.memberId, requestParameters.claimedRewardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiRegisterPurchaseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPurchase(requestParameters: MemberControllerApiRegisterPurchaseRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.registerPurchase(requestParameters.id, requestParameters.purchaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiSearchMemberEcModeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMemberEcMode(requestParameters: MemberControllerApiSearchMemberEcModeRequest, options?: AxiosRequestConfig): AxiosPromise<MemberEcModeDto> {
            return localVarFp.searchMemberEcMode(requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiSetTierLevelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTierLevel(requestParameters: MemberControllerApiSetTierLevelRequest, options?: AxiosRequestConfig): AxiosPromise<MemberDto> {
            return localVarFp.setTierLevel(requestParameters.id, requestParameters.updateTierLevelIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberControllerApiUnblockRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblock(requestParameters: MemberControllerApiUnblockRequest, options?: AxiosRequestConfig): AxiosPromise<MemberDto> {
            return localVarFp.unblock(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addGiftPoints operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiAddGiftPointsRequest
 */
export interface MemberControllerApiAddGiftPointsRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiAddGiftPoints
     */
    readonly id: string

    /**
     * 
     * @type {GiftPointsDto}
     * @memberof MemberControllerApiAddGiftPoints
     */
    readonly giftPointsDto: GiftPointsDto
}

/**
 * Request parameters for block operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiBlockRequest
 */
export interface MemberControllerApiBlockRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiBlock
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiBlock
     */
    readonly comment: string
}

/**
 * Request parameters for getClaimedRewardsForReport operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetClaimedRewardsForReportRequest
 */
export interface MemberControllerApiGetClaimedRewardsForReportRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetClaimedRewardsForReport
     */
    readonly rewardId: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetClaimedRewardsForReport
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetClaimedRewardsForReport
     */
    readonly to?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MemberControllerApiGetClaimedRewardsForReport
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MemberControllerApiGetClaimedRewardsForReport
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MemberControllerApiGetClaimedRewardsForReport
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getMemberBalances operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetMemberBalancesRequest
 */
export interface MemberControllerApiGetMemberBalancesRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetMemberBalances
     */
    readonly memberId: string

    /**
     * 
     * @type {boolean}
     * @memberof MemberControllerApiGetMemberBalances
     */
    readonly includeCollaborationDetails: boolean
}

/**
 * Request parameters for getMemberClaimedReward operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetMemberClaimedRewardRequest
 */
export interface MemberControllerApiGetMemberClaimedRewardRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetMemberClaimedReward
     */
    readonly memberId: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetMemberClaimedReward
     */
    readonly claimedRewardId: string
}

/**
 * Request parameters for getMemberClaimedRewards operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetMemberClaimedRewardsRequest
 */
export interface MemberControllerApiGetMemberClaimedRewardsRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetMemberClaimedRewards
     */
    readonly memberId: string

    /**
     * 
     * @type {Array<'CLAIMED' | 'REDEEMED' | 'EXPIRED'>}
     * @memberof MemberControllerApiGetMemberClaimedRewards
     */
    readonly statuses?: Array<'CLAIMED' | 'REDEEMED' | 'EXPIRED'>

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MemberControllerApiGetMemberClaimedRewards
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MemberControllerApiGetMemberClaimedRewards
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MemberControllerApiGetMemberClaimedRewards
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getMemberDetails operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetMemberDetailsRequest
 */
export interface MemberControllerApiGetMemberDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetMemberDetails
     */
    readonly id: string
}

/**
 * Request parameters for getMembers operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetMembersRequest
 */
export interface MemberControllerApiGetMembersRequest {
    /**
     * 
     * @type {'MEMBER' | 'GUEST' | 'BLOCKED'}
     * @memberof MemberControllerApiGetMembers
     */
    readonly status?: 'MEMBER' | 'GUEST' | 'BLOCKED'

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetMembers
     */
    readonly tierLevel?: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetMembers
     */
    readonly search?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MemberControllerApiGetMembers
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MemberControllerApiGetMembers
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MemberControllerApiGetMembers
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getTierProgress operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetTierProgressRequest
 */
export interface MemberControllerApiGetTierProgressRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetTierProgress
     */
    readonly id: string
}

/**
 * Request parameters for getTotalFiltered operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiGetTotalFilteredRequest
 */
export interface MemberControllerApiGetTotalFilteredRequest {
    /**
     * 
     * @type {'MEMBER' | 'GUEST' | 'BLOCKED'}
     * @memberof MemberControllerApiGetTotalFiltered
     */
    readonly status?: 'MEMBER' | 'GUEST' | 'BLOCKED'

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetTotalFiltered
     */
    readonly tierLevel?: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiGetTotalFiltered
     */
    readonly search?: string
}

/**
 * Request parameters for invite operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiInviteRequest
 */
export interface MemberControllerApiInviteRequest {
    /**
     * 
     * @type {InvitationDto}
     * @memberof MemberControllerApiInvite
     */
    readonly invitationDto: InvitationDto
}

/**
 * Request parameters for inviteGhostConsumer operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiInviteGhostConsumerRequest
 */
export interface MemberControllerApiInviteGhostConsumerRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiInviteGhostConsumer
     */
    readonly memberId: string
}

/**
 * Request parameters for notifyInvitation operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiNotifyInvitationRequest
 */
export interface MemberControllerApiNotifyInvitationRequest {
    /**
     * 
     * @type {InvitationDto}
     * @memberof MemberControllerApiNotifyInvitation
     */
    readonly invitationDto: InvitationDto
}

/**
 * Request parameters for redeemClaimedReward operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiRedeemClaimedRewardRequest
 */
export interface MemberControllerApiRedeemClaimedRewardRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiRedeemClaimedReward
     */
    readonly memberId: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiRedeemClaimedReward
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiRedeemClaimedReward
     */
    readonly claimedRewardId: string
}

/**
 * Request parameters for redeemMemberClaimedReward operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiRedeemMemberClaimedRewardRequest
 */
export interface MemberControllerApiRedeemMemberClaimedRewardRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiRedeemMemberClaimedReward
     */
    readonly memberId: string

    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiRedeemMemberClaimedReward
     */
    readonly claimedRewardId: string
}

/**
 * Request parameters for registerPurchase operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiRegisterPurchaseRequest
 */
export interface MemberControllerApiRegisterPurchaseRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiRegisterPurchase
     */
    readonly id: string

    /**
     * 
     * @type {PurchaseDto}
     * @memberof MemberControllerApiRegisterPurchase
     */
    readonly purchaseDto: PurchaseDto
}

/**
 * Request parameters for searchMemberEcMode operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiSearchMemberEcModeRequest
 */
export interface MemberControllerApiSearchMemberEcModeRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiSearchMemberEcMode
     */
    readonly search: string
}

/**
 * Request parameters for setTierLevel operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiSetTierLevelRequest
 */
export interface MemberControllerApiSetTierLevelRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiSetTierLevel
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTierLevelIdDto}
     * @memberof MemberControllerApiSetTierLevel
     */
    readonly updateTierLevelIdDto: UpdateTierLevelIdDto
}

/**
 * Request parameters for unblock operation in MemberControllerApi.
 * @export
 * @interface MemberControllerApiUnblockRequest
 */
export interface MemberControllerApiUnblockRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberControllerApiUnblock
     */
    readonly id: string
}

/**
 * MemberControllerApi - object-oriented interface
 * @export
 * @class MemberControllerApi
 * @extends {BaseAPI}
 */
export class MemberControllerApi extends BaseAPI {
    /**
     * 
     * @param {MemberControllerApiAddGiftPointsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public addGiftPoints(requestParameters: MemberControllerApiAddGiftPointsRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).addGiftPoints(requestParameters.id, requestParameters.giftPointsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiBlockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public block(requestParameters: MemberControllerApiBlockRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).block(requestParameters.id, requestParameters.comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetClaimedRewardsForReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getClaimedRewardsForReport(requestParameters: MemberControllerApiGetClaimedRewardsForReportRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getClaimedRewardsForReport(requestParameters.rewardId, requestParameters.from, requestParameters.to, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetMemberBalancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getMemberBalances(requestParameters: MemberControllerApiGetMemberBalancesRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getMemberBalances(requestParameters.memberId, requestParameters.includeCollaborationDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetMemberClaimedRewardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getMemberClaimedReward(requestParameters: MemberControllerApiGetMemberClaimedRewardRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getMemberClaimedReward(requestParameters.memberId, requestParameters.claimedRewardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetMemberClaimedRewardsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getMemberClaimedRewards(requestParameters: MemberControllerApiGetMemberClaimedRewardsRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getMemberClaimedRewards(requestParameters.memberId, requestParameters.statuses, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetMemberDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getMemberDetails(requestParameters: MemberControllerApiGetMemberDetailsRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getMemberDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetMembersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getMembers(requestParameters: MemberControllerApiGetMembersRequest = {}, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getMembers(requestParameters.status, requestParameters.tierLevel, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getMembersCounters(options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getMembersCounters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetTierProgressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getTierProgress(requestParameters: MemberControllerApiGetTierProgressRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getTierProgress(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiGetTotalFilteredRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public getTotalFiltered(requestParameters: MemberControllerApiGetTotalFilteredRequest = {}, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).getTotalFiltered(requestParameters.status, requestParameters.tierLevel, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiInviteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public invite(requestParameters: MemberControllerApiInviteRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).invite(requestParameters.invitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiInviteGhostConsumerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public inviteGhostConsumer(requestParameters: MemberControllerApiInviteGhostConsumerRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).inviteGhostConsumer(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiNotifyInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public notifyInvitation(requestParameters: MemberControllerApiNotifyInvitationRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).notifyInvitation(requestParameters.invitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiRedeemClaimedRewardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public redeemClaimedReward(requestParameters: MemberControllerApiRedeemClaimedRewardRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).redeemClaimedReward(requestParameters.memberId, requestParameters.businessEntityId, requestParameters.claimedRewardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiRedeemMemberClaimedRewardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public redeemMemberClaimedReward(requestParameters: MemberControllerApiRedeemMemberClaimedRewardRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).redeemMemberClaimedReward(requestParameters.memberId, requestParameters.claimedRewardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiRegisterPurchaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public registerPurchase(requestParameters: MemberControllerApiRegisterPurchaseRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).registerPurchase(requestParameters.id, requestParameters.purchaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiSearchMemberEcModeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public searchMemberEcMode(requestParameters: MemberControllerApiSearchMemberEcModeRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).searchMemberEcMode(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiSetTierLevelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public setTierLevel(requestParameters: MemberControllerApiSetTierLevelRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).setTierLevel(requestParameters.id, requestParameters.updateTierLevelIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberControllerApiUnblockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberControllerApi
     */
    public unblock(requestParameters: MemberControllerApiUnblockRequest, options?: AxiosRequestConfig) {
        return MemberControllerApiFp(this.configuration).unblock(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
