/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DownloadUrlDto } from '../models';
// @ts-ignore
import { ProblemDetail } from '../models';
// @ts-ignore
import { UploadUrlDto } from '../models';
/**
 * CsvControllerApi - axios parameter creator
 * @export
 */
export const CsvControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl1: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getDownloadUrl1', 'key', key)
            const localVarPath = `/csv/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} csvType 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl1: async (csvType: string, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvType' is not null or undefined
            assertParamExists('getUploadUrl1', 'csvType', csvType)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getUploadUrl1', 'filename', filename)
            const localVarPath = `/csv/upload/{csvType}`
                .replace(`{${"csvType"}}`, encodeURIComponent(String(csvType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CsvControllerApi - functional programming interface
 * @export
 */
export const CsvControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CsvControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadUrl1(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUrl1(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} csvType 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadUrl1(csvType: string, filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadUrl1(csvType, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CsvControllerApi - factory interface
 * @export
 */
export const CsvControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CsvControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CsvControllerApiGetDownloadUrl1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl1(requestParameters: CsvControllerApiGetDownloadUrl1Request, options?: AxiosRequestConfig): AxiosPromise<DownloadUrlDto> {
            return localVarFp.getDownloadUrl1(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CsvControllerApiGetUploadUrl1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl1(requestParameters: CsvControllerApiGetUploadUrl1Request, options?: AxiosRequestConfig): AxiosPromise<UploadUrlDto> {
            return localVarFp.getUploadUrl1(requestParameters.csvType, requestParameters.filename, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDownloadUrl1 operation in CsvControllerApi.
 * @export
 * @interface CsvControllerApiGetDownloadUrl1Request
 */
export interface CsvControllerApiGetDownloadUrl1Request {
    /**
     * 
     * @type {string}
     * @memberof CsvControllerApiGetDownloadUrl1
     */
    readonly key: string
}

/**
 * Request parameters for getUploadUrl1 operation in CsvControllerApi.
 * @export
 * @interface CsvControllerApiGetUploadUrl1Request
 */
export interface CsvControllerApiGetUploadUrl1Request {
    /**
     * 
     * @type {string}
     * @memberof CsvControllerApiGetUploadUrl1
     */
    readonly csvType: string

    /**
     * 
     * @type {string}
     * @memberof CsvControllerApiGetUploadUrl1
     */
    readonly filename: string
}

/**
 * CsvControllerApi - object-oriented interface
 * @export
 * @class CsvControllerApi
 * @extends {BaseAPI}
 */
export class CsvControllerApi extends BaseAPI {
    /**
     * 
     * @param {CsvControllerApiGetDownloadUrl1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvControllerApi
     */
    public getDownloadUrl1(requestParameters: CsvControllerApiGetDownloadUrl1Request, options?: AxiosRequestConfig) {
        return CsvControllerApiFp(this.configuration).getDownloadUrl1(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvControllerApiGetUploadUrl1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvControllerApi
     */
    public getUploadUrl1(requestParameters: CsvControllerApiGetUploadUrl1Request, options?: AxiosRequestConfig) {
        return CsvControllerApiFp(this.configuration).getUploadUrl1(requestParameters.csvType, requestParameters.filename, options).then((request) => request(this.axios, this.basePath));
    }
}
